import { atom } from "recoil";

export const ffmpegLogsAtom = atom({
  key: "ffmpegLogs",
  default: [],
});

export const userAtom = atom({
  key: "user",
  default: {
    uid: "",
    email: "",
    displayName: "",
    photoURL: "",
    isLoggedIn: null,
  },
});

export const userDataAtom = atom({
  key: "userData",
  default: {
    changed: false,
    openAI_API_KEY: "",
    claude_API_KEY: "",
    use_claude: false,
    promptTemplate: "",
    license: false,
    expiry: -1,
  },
});

export const currentActiveVideoAtom = atom({
  key: "currentActiveVideo",
  default: null,
});

export const videoProcessorProgressAtom = atom({
  key: "videoProcessorProgress",
  default: {
    msg: "",
    progress: 0,
  },
});

export const editorStateAtom = atom({
  key: "editorState",
  default: {
    video: null,
    srcURL: null,
    transcript: [],
    suggestions: [],
  },
});

export const openEditorAtom = atom({
  key: "openEditor",
  default: false,
});

export const openVideoMakerAtom = atom({
  key: "openVideoMaker",
  default: false,
});

export const videoMakerStateAtom = atom({
  key: "videoMakerState",
  default: {
    file: null,
    timechart: [],
  },
});

export const selectedClipIdsAtom = atom({
  key: "selectedClipIds",
  default: 0,
});

export const globalSelectionsAtom = atom({
  key: "globalSelections",
  default: {start: 0, end: 0},
});

export const selectionsChangesAtom = atom({
  key: "globalSelectionsChanges",
  default: true,
});

export const selectionRangeAtom = atom({
  key: "selectionRange",
  default: {start: 0, end: 0,startTime:0,endTime:0},
});