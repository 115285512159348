import React, { useContext, useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { useRecoilState } from "recoil";
import {
  openVideoMakerAtom,
  videoMakerStateAtom,
  videoProcessorProgressAtom,
} from "../atoms/atoms";
import { FfmpegContext } from "../context/FFMPEGContext";

const VideoMaker = () => {
  const [open, setOpen] = useRecoilState(openVideoMakerAtom);
  const [state, setState] = useRecoilState(videoMakerStateAtom);
  const [logs, setLogs] = useRecoilState(videoProcessorProgressAtom);

  const { current: ffmpeg } = useContext(FfmpegContext);

  useEffect(() => {
    if (open && state.timechart.length > 0) {
      // do something
      makeVideo(
        state.timechart.map((t) => ({
          start: t.start / 1000,
          end: t.end / 1000,
        }))
      );

      setState((p) => ({ ...p, timechart: [] }));
    }
  }, [open, state]);

  const makeVideo = async (suggestions) => {
    let parts = state.file.name.split(".");

    const ext = parts.pop();
    parts.push("_output_edited");
    const target = parts.join("")+"."+ext;

    let commands = [];
    let last_cmd = "";
    for (let i = 0; i < suggestions.length; i++) {
      const s = suggestions[i];
      let dm = `[0:v]trim=start=${s.start}:end=${s.end},setpts=PTS-STARTPTS[v${
        i + 1
      }];`;
      dm += `[0:a]atrim=start=${s.start}:end=${s.end},asetpts=PTS-STARTPTS[a${
        i + 1
      }];`;
      commands.push(dm);
      last_cmd += `[v${i + 1}][a${i + 1}]`;
    }

    commands = commands.join("");
    last_cmd += `concat=n=${suggestions.length}:v=1:a=1[outv][outa]`;

    commands += last_cmd;
    // console.log(commands);

  
    await ffmpeg.exec([
      "-i",
      state.file.name,
      "-filter_complex",
      commands,
      "-map",
      "[outv]",
      "-map",
      "[outa]",
      target,
    ]);

    console.log("here is maker");

    const data = await ffmpeg.readFile(target);
    let blob = new Blob([data.buffer], { type: "video/" + ext });

    //download it
    let a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = target;
    a.click();
    a.remove();

    // delete the file
    await ffmpeg.deleteFile(target);
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Processing Video</DialogTitle>
      <DialogContent>
        {logs.progress >= 100 && (
          <Stack>
            <CircularProgress />
            <Typography>Waiting to start</Typography>
          </Stack>
        )}

        {logs.progress <= 100 && logs.msg}
      </DialogContent>
    </Dialog>
  );
};

export default VideoMaker;
