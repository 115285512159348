import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Avatar, IconButton, Stack } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { userAtom } from "../atoms/atoms";
import { useRecoilState } from "recoil";
import SwitchVideoIcon from "@mui/icons-material/SwitchVideo";

import { getAuth, signOut } from "firebase/auth";
import { useState } from "react";
import Settings from "./Settings";

const AppBar = () => {
  const [user, setUser] = useRecoilState(userAtom);
  const [settingsOpen, setSettingsOpen] = useState(false);

  return (
    <MuiAppBar position="static">
      <Settings open={settingsOpen} setOpen={setSettingsOpen} />
      <Toolbar
        variant="dense"
        disableGutters
        sx={{ minHeight: 45, height: 45 }}
      >
        <Stack display="flex" justifyContent="space-between"  padding="0 12px" direction="row" width="100%" boxSizing="border-box">
          <Box display="flex" alignItems="center" gap="5px">
            <SwitchVideoIcon />
            <Typography variant="h6">Limitless Shorts!</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <IconButton onClick={() => setSettingsOpen(true)}>
              <SettingsIcon />
            </IconButton>
            <br></br>
            <br></br>
            <Avatar
              sizes="20"
              src={user.photoURL}
              style={{ width: "25px", height: "25px", marginLeft: "20px" }}
            >
              {user.displayName[0]}
            </Avatar>
            <Button
              onClick={() => {
                signOut(getAuth());
              }}
              color="inherit"
            >
              Logout
            </Button>
          </Box>
        </Stack>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
