import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { RecoilRoot } from "recoil";
import app from "./firebase.js";


const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#ffe6e6',
    },
    secondary: {
      main: '#e1afd1',
    },
    background: {
      default: '#242424',
      paper: '#242424',
    },
    divider: 'rgba(84,84,84,0.12)',
    text: {
      primary: 'rgba(255,255,255,0.87)',
    },
  },
});
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </ThemeProvider>
  </React.StrictMode>
);
