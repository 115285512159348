import { Box } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { Player } from "video-react";
import { globalSelectionsAtom, selectedClipIdsAtom } from "../atoms/atoms";
import { useRecoilState } from "recoil";

const EditPanel = ({ vdoSrc }) => {
  const [globalSelection, setGlobalSelection] =
    useRecoilState(globalSelectionsAtom);
  const [selectedClip, setSelectedClip] = useRecoilState(selectedClipIdsAtom);
  const player = useRef(null);
  // const [nowTime, setNowTime] = React.useState([0, 999999]);
  // const [time, setTime] = React.useState(0);

  useEffect(() => {
    if (!player.current) return;
    if (!globalSelection || selectedClip >= globalSelection.length) return;

    const clip = globalSelection[selectedClip];
    player.current.seek(clip.start);
  }, [selectedClip, player, globalSelection]);

  // useEffect(() => {
  //   if (!player.current) return;

  //   let it = setInterval(() => {
  //     if (player.current.getState().currentTime > nowTime[1]) {
  //       player.current.seek(nowTime[0]);
  //     }
  //   },500);

  //   return () => {
  //     clearInterval(it);
  //   };
  // }, [nowTime, player]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "black",
        maxHeight: "350px",
      }}
    >
      <Player fluid={false} height={338} width={600} ref={player} control>
        {vdoSrc && <source src={vdoSrc} />}
      </Player>
    </div>
  );
};

export default EditPanel;
