import {
  Alert,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { TextField } from "./Settings";
import { userDataAtom } from "../atoms/atoms";
import { useRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { toast } from "react-toastify";

const LicenseActivator = () => {
  const [userData, setUserData] = useRecoilState(userDataAtom);
  const [key, setKey] = useState("");
  const [loading, setLoading] = useState(false);



  const activate = async () => {
    setLoading(true);

    try {
      const fn = httpsCallable(getFunctions(), "activateLicense");
      const res = await fn({ key });

      const licenseDoc = await getDoc(
        doc(getFirestore(), "userPrivate", getAuth().currentUser.uid)
      );

      if (licenseDoc.exists()) {
        setUserData((p) => ({ ...p, ...licenseDoc.data() }));
      } else {
        throw new Error("License not found");
      }
    } catch (e) {
      console.error(e);
      toast.error("Failed to activate license");
    }

    setLoading(false);
  };

  return (
    <>
      {!userData.license && (
        <Alert severity="info">Add your license key to activate the app.</Alert>
      )}

      {(!userData.license || userData.expiry < Date.now()) && (
        <Stack justifyContent="space-between" display="flex" direction="row">
          <TextField
            style={{ width: "70%" }}
            label="License key"
            placeholder="Add your license key here"
            value={key}
            onChange={(e) => setKey(e.target.value)}
          />
          <Button
            variant="contained"
            style={{ width: "20%" }}
            onClick={activate}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Activate"}
          </Button>
        </Stack>
      )}

      {userData.license && userData.expiry && userData.expiry < Date.now() && (
        <Alert severity="warning">
          Your license has expired. Please renew your license to continue using
          the app.
        </Alert>
      )}

      {userData.expiry &&
        userData.expiry > 0 &&
        userData.expiry > Date.now() && (
          <Stack justifyContent="center" display="flex" direction="row">
            <Typography variant="caption" style={{ color: "yellow" }}>
              your license is valid till{" "}
              {new Date(userData.expiry).toLocaleDateString()}
            </Typography>
          </Stack>
        )}
    </>
  );
};

export default LicenseActivator;
