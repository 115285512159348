import React, { useState, useRef, useEffect } from "react";
import RoomIcon from "@mui/icons-material/Room";
import {
  editorStateAtom,
  globalSelectionsAtom,
  selectedClipIdsAtom,
  selectionRangeAtom,
  selectionsChangesAtom,
} from "../atoms/atoms";
import { useRecoilState } from "recoil";
import { getSentences } from "../pages/util";
import { secToTxt } from "../pages/VideoProcessor";

const TextTracker = () => {
  const [editorState, setEditorState] = useRecoilState(editorStateAtom);
  const [selectedClip, setSelectedClip] = useRecoilState(selectedClipIdsAtom);
  const [globalSelection, setGlobalSelection] =
    useRecoilState(globalSelectionsAtom);

  const [words, setWords] = useState([]);
  const [sentences, setSentences] = useState([]);

  const [selectionRange, setSelectionRange] =
    useRecoilState(selectionRangeAtom);

  const draggingStartRef = useRef(false);
  const draggingEndRef = useRef(false);

  useEffect(() => {
    setWords(editorState.transcript[1]);
    setSentences(getSentences(editorState.transcript));
  }, [editorState]);

  useEffect(() => {
    let tm = setTimeout(() => {
      document.querySelector(".start-word")?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }, 200);
    return () => {
      clearTimeout(tm);
    };
  }, [selectedClip]);

  useEffect(() => {
    setGlobalSelection((prevSelection) => {
      if (
        !prevSelection ||
        selectedClip >= prevSelection.length ||
        !words[selectionRange.start] ||
        !words[selectionRange.end]
      )
        return prevSelection;

      // console.log("Selection Range called from TextTracker");

      let s = { ...globalSelection[selectedClip] };
      s.selection = [selectionRange.start, selectionRange.end];
      s.start = selectionRange.startTime;
      s.end = selectionRange.endTime;

      let newSelection = [...prevSelection];
      newSelection[selectedClip] = s;

      return newSelection;
    });
  }, [selectionRange, words, selectedClip]);

  // console.log("Selection Range:", globalSelection, selectionRange);

  const handleWordClick = (wordIndex, buttonIndex) => {
    if (buttonIndex === 1) {
      if (wordIndex <= selectionRange.end) {
        setSelectionRange((prevRange) => ({ ...prevRange, start: wordIndex, startTime: words[wordIndex].start}));
      }
    } else {
      if (wordIndex >= selectionRange.start) {
        setSelectionRange((prevRange) => ({ ...prevRange, end: wordIndex, endTime: words[wordIndex].end}));
      }
    }
  };

  const handleMouseMove = (e) => {
    if (draggingStartRef.current || draggingEndRef.current) {
      const wordIndex = parseInt(e.target.dataset.index, 10);
      if (draggingStartRef.current) {
        if (wordIndex <= selectionRange.end) {
          setSelectionRange((prevRange) => ({
            ...prevRange,
            start: wordIndex,
            startTime: words[wordIndex].start,
          }));
        }
      } else if (draggingEndRef.current) {
        if (wordIndex >= selectionRange.start) {
          setSelectionRange((prevRange) => ({ ...prevRange, end: wordIndex  , endTime: words[wordIndex].end}));
        }
      }
    }
  };

  const handleMouseUp = () => {
    draggingStartRef.current = false;
    draggingEndRef.current = false;
  };

  const handleDragStart = (e, type) => {
    e.dataTransfer.setData("text/plain", type);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const type = e.dataTransfer.getData("text/plain");
    const wordIndex = parseInt(e.target.dataset.index, 10);
    if (type === "start" && wordIndex <= selectionRange.end) {
      setSelectionRange((prevRange) => ({
        ...prevRange,
        start: wordIndex,
        startTime: words[wordIndex].start,
      }));
    } else if (type === "end" && wordIndex >= selectionRange.start) {
      setSelectionRange((prevRange) => ({
        ...prevRange,
        end: wordIndex,
        endTime: words[wordIndex].end,
      }));
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  // const selectedText = words
  //   .slice(selectionRange.start, selectionRange.end + 1)
  //   .join(" ");
  // console.log("Selected Text:", selectedText);

  return (
    <div
      style={{
        width: "100%",
        marginTop: "20px",
      }}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {sentences.map((sentence, sentenceIndex) => (
        <div
          key={sentenceIndex}
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            marginBottom: "12px",
          }}
        >
          <div
            style={{
              textAlign: "right",
              color: "whitesmoke",
              width: "100px",
              whiteSpace: "nowrap",
              margin: "0 25px 0 12px",
              marginTop: "2px",
            }}
          >
            [{secToTxt(sentence.start)} : {secToTxt(sentence.end)}]
          </div>
          <div style={{ width: "70%", textAlign: "left" }}>
            {sentence.text.split(/\s+/).map((word, index) => {
              const wordIndex = sentence.startIndex + index;
              const isSelected =
                wordIndex >= selectionRange.start &&
                wordIndex <= selectionRange.end;
              const firstHalf = word.substring(0, Math.ceil(word.length / 2));
              const secondHalf = word.substring(Math.ceil(word.length / 2));
              return (
                <div
                  className={
                    wordIndex === selectionRange.start ? "start-word" : ""
                  }
                  key={wordIndex}
                  style={{
                    position: "relative",
                    display: "inline-block",
                    margin: "0",
                  }}
                  data-index={wordIndex}
                >
                  <div>
                    <button
                      style={{
                        color: "white",
                        fontWeight: "600",
                        fontSize: "16px",
                        cursor: "pointer",
                        backgroundColor: isSelected
                          ? "GrayText"
                          : "transparent",
                        border: "none",
                        padding: "0 0px 2px 2px",
                        margin: "0",
                      }}
                      onDoubleClick={() => handleWordClick(wordIndex, 1)}
                      data-index={wordIndex}
                    >
                      {firstHalf}
                    </button>
                    <button
                      style={{
                        color: "white",
                        fontWeight: "600",
                        fontSize: "16px",
                        cursor: "pointer",
                        backgroundColor: isSelected
                          ? "GrayText"
                          : "transparent",
                        border: "none",
                        padding: "0 2px 2px 0px",
                        margin: "0",
                      }}
                      onDoubleClick={() => handleWordClick(wordIndex, 2)}
                      data-index={wordIndex}
                    >
                      {secondHalf}
                    </button>
                  </div>
                  {isSelected && (
                    <React.Fragment>
                      {wordIndex === selectionRange.start && (
                        <span
                          className="start-index-indicator-icon"
                          style={{
                            display: "flex",
                            padding: "2px 0",
                            position: "absolute",
                            zIndex: "999",
                            borderRadius: "2px",
                            top: "-18px",
                            left: "-8px",
                            cursor: "grab",
                          }}
                          draggable
                          onDragStart={(e) => handleDragStart(e, "start")}
                          data-index={wordIndex}
                          onMouseDown={() => (draggingStartRef.current = true)}
                        >
                          <RoomIcon
                            style={{
                              color: "rgb(0, 255, 194)",
                              width: "20px",
                              height: "20px",
                            }}
                          />
                        </span>
                      )}
                      {wordIndex === selectionRange.end && (
                        <span
                          className="end-index-indicator-icon"
                          style={{
                            display: "flex",
                            padding: "2px 0",
                            zIndex: "999",
                            borderRadius: "2px",
                            position: "absolute",
                            top: "18px",
                            right: "-8px",
                            cursor: "grab",
                          }}
                          draggable
                          onDragStart={(e) => handleDragStart(e, "end")}
                          data-index={wordIndex}
                          onMouseDown={() => (draggingEndRef.current = true)}
                        >
                          <RoomIcon
                            style={{
                              color: "red",
                              width: "20px",
                              height: "20px",
                              transform: "rotate(180deg)",
                            }}
                          />
                        </span>
                      )}
                    </React.Fragment>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TextTracker;
