import React, { useState, useContext, useEffect, useRef } from "react";
import { FfmpegContext } from "../context/FFMPEGContext";
import {
  Box,
  Button,
  Stack,
  Divider,
  IconButton,
  LinearProgress,
  Switch,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  currentActiveVideoAtom,
  editorStateAtom,
  globalSelectionsAtom,
  openEditorAtom,
  selectedClipIdsAtom,
  selectionRangeAtom,
  userDataAtom,
  videoProcessorProgressAtom,
} from "../atoms/atoms";
import { fetchFile } from "@ffmpeg/util";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import Loader from "../components/Loader";
import { Delete, Download, Edit, Surfing } from "@mui/icons-material";
import TRANSCPT from "./tmp.json";
import { findSegmentText, findSelection, makeVideos } from "./util";
import { toast } from "react-toastify";

const STATUS = {
  WAITING: "WAITING",
  AUDIO_PROCESSING: "AUDIO_PROCESSING",
  AUDIO_TRANSCRIPTING: "AUDIO_TRANSCRIPTING",
  EXTRACT_IMPORTANT: "EXTRACT_IMPORTANT",
  SPLITING_VIDEO: "SPLITING_VIDEO",
  DONE: "DONE",
};

const statusText = {
  [STATUS.WAITING]: "Waiting for turn",
  [STATUS.AUDIO_PROCESSING]: "Processing audio",
  [STATUS.AUDIO_TRANSCRIPTING]: "Transcripting audio",
  [STATUS.EXTRACT_IMPORTANT]: "Extracting important segments information",
  [STATUS.SPLITING_VIDEO]: "Spliting video",
  [STATUS.DONE]: "Done",
};

export const secToTxt = (sec) => {
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec % 3600) / 60);
  const seconds = Math.floor(sec % 60);
  return `${hours}:${minutes}:${seconds}`;
};

const convertTranscript = (t) => {
  return "[" + t.start + " - " + t.end + "] " + t.text;
};

const getSuggestions = async (transcript, userData, setStatus) => {
  const fn = httpsCallable(getFunctions(), "get_suggestions");
  const result = await fn({
    prompt: userData.promptTemplate,
    transcript: JSON.stringify(transcript[0]),
    key: userData.claude_API_KEY,
  });

  let suggestions = result.data.split("\n");
  suggestions = suggestions.map((s) => s.trim());
  suggestions = suggestions.filter((s) => s.length > 0);

  if (suggestions.length == 0) {
    return [];
  }

  if (isNaN(parseInt(suggestions[0][0]))) {
    suggestions.shift();
  }

  for (let i = 0; i < suggestions.length; i++) {
    let mt = suggestions[i];
    if (mt && mt.length > 0) {
      let t = mt.split(":");
      suggestions[i] = {
        start: parseFloat(t[0]),
        end: parseFloat(t[1]),
      };
      suggestions[i].selection = findSelection(
        suggestions[i].start,
        suggestions[i].end,
        transcript
      );
    }
  }

  return suggestions;
};

const downloadTranscript = (transcript, name) => {
  let txt = "";
  transcript.forEach((t) => {
    txt +=
      "[" + secToTxt(t.start) + " - " + secToTxt(t.end) + "] " + t.text + "\n";
  });
  const blob = new Blob([txt], { type: "text/plain" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = name + ".txt";
  a.click();

  URL.revokeObjectURL(url);
};

const audioTranscribe = async (file, video, userData) => {
  const audioPath = getAuth().currentUser.uid + "/" + video.name + ".mp3";
  const storageRef = ref(getStorage(), audioPath);

  await uploadBytes(storageRef, file);
  const fn = httpsCallable(getFunctions(), "get_transcript");
  const result = await fn({
    fileName: audioPath,
    key: userData.openAI_API_KEY,
  });

  console.log(result.data);

  return result.data;

  return TRANSCPT;
};

const handleError = (status) => {
  if (status == STATUS.AUDIO_TRANSCRIPTING) {
    toast.error("Failed to transcribe audio. Check your OpenAI API KEY");
  } else if (status == STATUS.EXTRACT_IMPORTANT) {
    toast.warn("Make sure you don't specify output format in the prompt.");
    toast.error(
      "Failed to extract important segments. Check your Claude API KEY"
    );
  } else {
    toast.error("Failed to process video");
  }
};

const VideoProcessor = ({ video, setFinished, deleteThis }) => {
  const [currentActiveVideo, setCurrentActiveVideo] = useRecoilState(
    currentActiveVideoAtom
  );
  const setGlobalSelection = useSetRecoilState(globalSelectionsAtom);
  const [progress, setProgress] = useRecoilState(videoProcessorProgressAtom);
  const setOpenEditor = useSetRecoilState(openEditorAtom);
  const setEditorState = useSetRecoilState(editorStateAtom);
  const setSelectedRange = useSetRecoilState(selectionRangeAtom);
  const setSelectedItems = useSetRecoilState(selectedClipIdsAtom);
  const [autoMakeVideo, setAutoMakeVideo] = useState(false);

  const { current: ffmpeg } = useContext(FfmpegContext);
  const [status, setStatus] = useState(STATUS.WAITING);
  const [userData, setUserData] = useRecoilState(userDataAtom);
  const [transcript, setTranscript] = useState(null);
  const [suggested, setSuggested] = useState(null);
  const [splitProgress, setSplitprogress] = useState(null);

  const videoRef = useRef(null);

  // console.log("currentActiveVideo", currentActiveVideo, video, status);
  // useEffect(() => {
  //   return () => {
  //     // cleanup
  //     // if (ffmpeg) {
  //     //   ffmpeg.deleteFile(video.file.name);
  //     // }
  //   };
  // }, [ffmpeg]);

  useEffect(() => {
    if (currentActiveVideo === video.id && status === STATUS.WAITING) {
      console.log("startTask");
      startTask();
    }
  }, [currentActiveVideo, status]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.src = URL.createObjectURL(video.file);
    }
  }, [video, videoRef]);

  const getAudio = async () => {
    const target = video.name + ".mp3";

    await ffmpeg.writeFile(video.file.name, await fetchFile(video.file));
    await ffmpeg.exec([
      "-i",
      video.file.name,
      "-vn",
      "-acodec",
      "libmp3lame",
      "-b:a",
      "192k",
      target,
    ]);

    const data = await ffmpeg.readFile(target);
    let blob = new Blob([data.buffer], { type: "audio/mp3" });
    ffmpeg.deleteFile(target);

    return blob;
  };

  async function startTask() {
    try {
      setStatus(STATUS.AUDIO_PROCESSING);
      const audio = await getAudio();

      setStatus(STATUS.AUDIO_TRANSCRIPTING);
      const transcript = await audioTranscribe(audio, video, userData);
      setTranscript(transcript);

      setStatus(STATUS.EXTRACT_IMPORTANT);
      const suggestions = await getSuggestions(transcript, userData);
      setSuggested(suggestions);

      if (autoMakeVideo) {
        setStatus(STATUS.SPLITING_VIDEO);
        await makeVideos(suggestions, ffmpeg, video, setSplitprogress);
      }
      setStatus(STATUS.DONE);
      setFinished(video.id);
    } catch (e) {
      console.log(e);
      handleError(status);
    }
  }

  function editorOpener() {
    setSelectedItems(0);
    setGlobalSelection(JSON.parse(JSON.stringify(suggested)));
    setSelectedRange({
      startTime: suggested[0].start,
      endTime: suggested[0].end,
      start: suggested[0].selection[0],
      end: suggested[0].selection[1],
    });
    setEditorState({
      video: video,
      srcURL: URL.createObjectURL(video.file),
      transcript: JSON.parse(JSON.stringify(transcript)),
    });

    setOpenEditor(true);
  }

  return (
    <Box
      padding="10px"
      border="1px solid #ccc"
      borderRadius="5px"
      display="flex"
      flexDirection="row"
      bgcolor="#333"
      boxShadow="0 0 20px rgba(255,255,1,0.1)"
      gap={"15px"}
      position="relative"
      maxHeight="400px"
      boxSizing="border-box"
      width="100%"
    >
      <Box width="40%" boxSizing="border-box">
        <Typography
          variant="h6"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {video.name}
        </Typography>
        <video autoPlay={false} ref={videoRef} style={{height:"85%", width:"100%"}} />
      </Box>

      <Divider
        orientation="vertical"
        flexItem
        style={{
          backgroundColor: "#ccc",
        }}
      />

      {/* Toolbox */}
      <Box width="55%">
        <Stack
          display="flex"
          alignItems="center"
          bgcolor="black"
          padding="5px 10px"
          fontWeight="700"
          color="white"
          borderRadius="15px"
        >
          {statusText[status]}
        </Stack>

        {transcript && (
          <Box
            width="100%"
            alignItems={"center"}
            margin="10px 0"
            gap="12px"
            display="flex"
            flexWrap="wrap"
          >
            <Button
              variant="outlined"
              startIcon={<Download />}
              onClick={() => downloadTranscript(transcript[0], video.name)}
            >
              Download Transcript
            </Button>

            {/* {suggested && (
              <Button
                variant="outlined"
                startIcon={<Download />}
                onClick={() => downloadTranscript(suggested)}
              >
                Download Suggested
              </Button>
            )} */}

            {suggested && (
              <Button
                variant="contained"
                startIcon={<Edit />}
                onClick={editorOpener}
              >
                Editor
              </Button>
            )}

            {suggested && (
              <Button
                variant="contained"
                startIcon={<Download />}
                onClick={() => {
                  setStatus(STATUS.SPLITING_VIDEO);
                  makeVideos(suggested, ffmpeg, video, setSplitprogress).then(
                    () => {
                      setStatus(STATUS.DONE);
                    }
                  );
                }}
              >
                Download All Clips
              </Button>
            )}

            {/* {outputVideo && (
              <Button
                variant="contained"
                startIcon={<Download />}
                onClick={() => {
                  const url = URL.createObjectURL(outputVideo);
                  const a = document.createElement("a");
                  a.href = url;
                  a.download = video.name + "_output.mp4";
                  a.click();
                  URL.revokeObjectURL(url);
                }}
              >
                Video Ready
              </Button>
            )} */}
          </Box>
        )}

        {video.id === currentActiveVideo &&
          status == STATUS.AUDIO_PROCESSING && (
            <Typography variant="body1">{progress.msg}</Typography>
          )}

        {video.id === currentActiveVideo &&
          ![
            STATUS.AUDIO_PROCESSING,
            STATUS.WAITING,
            STATUS.SPLITING_VIDEO,
            STATUS.DONE,
          ].includes(status) && (
            <Box width="10%" display="flex" alignItems="center" height="100px">
              <Loader scale={0.5} />
            </Box>
          )}

        {video.id === currentActiveVideo && status == STATUS.SPLITING_VIDEO && (
          <Typography variant="body1">{splitProgress}</Typography>
        )}
      </Box>

      <Box
        position="absolute"
        bottom="10px"
        right="10px"
        onMouseEnter={(e) => {
          e.currentTarget.children[0].style.visibility = "visible";
        }}
        onMouseLeave={(e) => {
          e.currentTarget.children[0].style.visibility = "hidden";
        }}
      >
        <Box display="flex" gap="10px" visibility="hidden">
          <FormControlLabel
            control={
              <Switch
                checked={autoMakeVideo}
                onChange={(e) => setAutoMakeVideo(e.target.checked)}
              />
            }
            label="Auto download Video"
          />
          <IconButton
            onClick={() => {
              ffmpeg.deleteFile(video.file.name);
              deleteThis(video.id);
            }}
          >
            <Delete />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default VideoProcessor;
