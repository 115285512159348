import { useRecoilState } from "recoil";
import "./App.css";
import { ffmpegLogsAtom, userAtom, userDataAtom } from "./atoms/atoms";
import { FFMPEGProvider } from "./context/FFMPEGContext";
import { useContext, useEffect } from "react";
import Login from "./pages/Login";
import { doc, getFirestore, getDoc } from "firebase/firestore";
import Loader from "./components/Loader";
import { Stack } from "@mui/material";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import AppBar from "./components/AppBar";
import Home from "./pages/Home";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [user, setUser] = useRecoilState(userAtom);
  const [userData, setUserData] = useRecoilState(userDataAtom);

  useEffect(() => {
    if (!user.isLoggedIn) return;

    const populateUserData = async () => {
      let data = null;
      while (true) {
        const docRef = doc(getFirestore(), "users", user.uid);
        const userDoc = await getDoc(docRef);
        if (userDoc.exists()) {
          data = userDoc.data();
          break;
        }
        await new Promise((resolve) => setTimeout(resolve, 500));
      }

      const privateDoc= doc(getFirestore(), "userPrivate", user.uid);
      const privateData = await getDoc(privateDoc);
      if (privateData.exists()) {
        data = { ...data, ...privateData.data(),fetched: true};
      }

      setUserData(data);
    };

    populateUserData();
  }, [user]);

  useEffect(() => {
    onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        setUser({
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
          photoURL: user.photoURL,
          isLoggedIn: true,
        });
      } else {
        setUser({
          uid: "",
          email: "",
          displayName: "",
          photoURL: "",
          isLoggedIn: false,
        });
      }
    });
  }, []);

  // if (Date.now() > 1717610400000) {
  //   return <h1>Contact Developer</h1>;
  // }

  if (user.isLoggedIn === null) {
    return (
      <Stack width="100%" height="100vh">
        <Loader />
      </Stack>
    );
  }

  if (user.isLoggedIn === false) {
    return <Login />;
  }

  return (
    <FFMPEGProvider>
      <AppBar />
      <Home />
      <ToastContainer />
      {/* <Editor /> */}
    </FFMPEGProvider>
  );
}

export default App;
