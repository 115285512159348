import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Alert,
  Checkbox,
  Chip,
  CircularProgress,
  Slide,
  Slider,
} from "@mui/material";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  globalSelectionsAtom,
  selectedClipIdsAtom,
  selectionRangeAtom,
  selectionsChangesAtom,
} from "../atoms/atoms";
import { FfmpegContext } from "../context/FFMPEGContext";
import { makeVideo } from "../pages/util";
import { secToTxt } from "../pages/VideoProcessor";
import { Add, AddBox, IndeterminateCheckBox } from "@mui/icons-material";

function getVideoCover(url, seekTo = 0.0) {
  return new Promise((resolve, reject) => {
    // load the file to a video player
    const videoPlayer = document.createElement("video");
    videoPlayer.setAttribute("src", url);
    videoPlayer.load();
    videoPlayer.addEventListener("error", (ex) => {
      reject("error when loading video file", ex);
    });
    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener("loadedmetadata", () => {
      // seek to user defined timestamp (in seconds) if possible
      if (videoPlayer.duration < seekTo) {
        reject("video is too short.");
        return;
      }
      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        videoPlayer.currentTime = seekTo;
      }, 200);
      // extract video thumbnail once seeking is complete
      videoPlayer.addEventListener("seeked", () => {
        console.log("video is now paused at %ss.", seekTo);
        // define a canvas to have the same dimension as the video
        const canvas = document.createElement("canvas");
        canvas.width = videoPlayer.videoWidth;
        canvas.height = videoPlayer.videoHeight;
        // draw the video frame to canvas
        const ctx = canvas.getContext("2d");
        ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
        // return the canvas image as a blob
        ctx.canvas.toBlob(
          (blob) => {
            resolve(blob);
          },
          "image/jpeg",
          0.75 /* quality */
        );
      });
    });
  });
}

export default function YourClipsCard({
  id,
  title,
  startTime,
  endTime,
  srcURL,
  video,
  changeValue,
  maxTime,
  selection,
}) {
  const [selected, setSelected] = useRecoilState(selectedClipIdsAtom);
  const [thumbnail, setThumbnail] = useState(null);
  const { current: ffmpeg } = React.useContext(FfmpegContext);
  const [makingVideo, setMakingVideo] = useState(false);
  const setSelectionUpdated = useSetRecoilState(selectionsChangesAtom);
  const setSelectionRange = useSetRecoilState(selectionRangeAtom);
  const [changeSelected, setChangeSelected] = useState(0);

  React.useEffect(() => {
    getVideoCover(srcURL, startTime).then((blob) => {
      setThumbnail(URL.createObjectURL(blob));
    });

    return () => {
      if (thumbnail) {
        URL.revokeObjectURL(thumbnail);
      }
    };
  }, [srcURL, startTime]);

  const downloadThisClip = () => {
    setMakingVideo(true);

    const ext = video.file.name.split(".")[1];
    const target = video.name + "_output_" + id + "." + ext;
    makeVideo(startTime, endTime, ffmpeg, target, ext, video).then(() => {
      setMakingVideo(false);
    });
  };

  const handleChange = (e, v) => {
    if (changeValue) changeValue(v);
  };

  return (
    <React.Fragment>
      <Box
        className="pointer-box"
        onClick={() => {
          if (selected != id) {
            setSelected(id);
            setSelectionRange({
              start: selection[0],
              end: selection[1],
              startTime: startTime,
              endTime: endTime,
            });
          }
        }}
        sx={{
          height: "150px",
          margin: "0 12px",
          border: `${selected === id ? 2 : 1}px solid ${
            selected === id ? "green" : "#422770"
          }`,
          borderRadius: "4px",
          backgroundColor: "transparent",
          marginBottom: "10px",
          position: "relative",
          cursor: "pointer",
          "&:hover .hover-checkbox, .hover-checkbox:checked": {
            display: "block",
          },
        }}
      >
        <Card
          sx={{
            display: "flex",
            backgroundColor: "transparent",
            boxShadow: "none",
          }}
        >
          <Box as="div">
            <CardMedia
              component="img"
              sx={{ width: "110px", height: "80px", padding: "10px" }}
              image={thumbnail}
              alt=""
            />
          </Box>
          <Box
            width="100%"
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "8px",
            }}
          >
            <CardContent sx={{ padding: "0" }}>
              <Typography sx={{ fontSize: "14px", marginRight: "22px" }}>
                {title}
              </Typography>
            </CardContent>

            <Box
              width="100%"
              sx={{
                alignItems: "center",
                marginRight: "8px",
                position: "relative",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <Chip
                label={`${(endTime - startTime).toFixed(2)}s`}
                size="small"
                variant="outlined"
                sx={{ width: "60px", borderRadius: "10%" }}
              />

              <IconButton
                disabled={selected != id}
                onClick={() => {
                  if (changeSelected)
                    changeValue([startTime, endTime - 1 < 0 ? 0 : endTime - 1]);
                  else
                    changeValue([
                      startTime - 1 < 0 ? 0 : startTime - 1,
                      endTime,
                    ]);
                }}
              >
                <IndeterminateCheckBox
                  style={{ color: selected == id ? "#fff" : "grey" }}
                />
              </IconButton>

              <IconButton
                onClick={() => {
                  if (changeSelected)
                    changeValue([
                      startTime,
                      endTime + 1 > maxTime ? maxTime : endTime + 1,
                    ]);
                  else
                    changeValue([
                      startTime + 1 > maxTime ? maxTime : startTime + 1,
                      endTime,
                    ]);
                }}
                disabled={selected != id}
              >
                <AddBox style={{ color: selected == id ? "#fff" : "grey" }} />
              </IconButton>

              <br></br>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-evenly"
            >
              <Typography variant="caption">
                [{" "}
                <label
                  onClick={() => setChangeSelected(0)}
                  style={
                    changeSelected == 0 ? { color: "green" } : { color: "#fff" }
                  }
                >
                  {" "}
                  {secToTxt(startTime)}{" "}
                </label>
                :{" "}
                <label
                  onClick={() => setChangeSelected(1)}
                  style={
                    changeSelected ? { color: "green" } : { color: "#fff" }
                  }
                >
                  {secToTxt(endTime)}
                </label>{" "}
                ]
              </Typography>
              <IconButton disabled={makingVideo} onClick={downloadThisClip}>
                {makingVideo ? (
                  <CircularProgress size={24} />
                ) : (
                  <DownloadIcon sx={{ color: "#fff" }} />
                )}
              </IconButton>
            </Box>
          </Box>
        </Card>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "0 8px",
          }}
        >
          <Slider
            disabled={selected !== id}
            onChange={handleChange}
            onChangeCommitted={() => setSelectionUpdated((p) => !p)}
            step={0.01}
            max={maxTime}
            style={{ width: "99%" }}
            value={[startTime, endTime]}
            size="small"
          />
        </Box>
        {/* <Checkbox
          className="hover-checkbox"
          size="small"
          checked={selected[index]}
          onChange={() => handleCheckboxChange(index)}
          sx={{
            margin: "0",
            color: "#00ffc2",
            position: "absolute",
            right: "0",
            top: "0",
            display: selected[index] ? "block" : "none",
            "&.Mui-checked": {
              color: "#00ffc2",
            },
          }}
        /> */}
      </Box>
    </React.Fragment>
  );
}
