import { useRecoilState } from "recoil";
import { editorStateAtom, openEditorAtom } from "../atoms/atoms";
import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  Grid,
  Stack,
  Button,
  Drawer,
  useMediaQuery,
} from "@mui/material";
import EditPanel from "../components/EditPanel";
import TextTracker from "../components/TextTracker";
import ClipBar from "../components/ClipBar";
import { VideoCameraBack } from "@mui/icons-material";

const VideoEditor = () => {
  const [editorState, setEditorState] = useRecoilState(editorStateAtom);
  const [editorOpen, setEditorOpen] = useRecoilState(openEditorAtom);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMdOrBelow = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  useEffect(() => {
    return () => {
      if (editorState.srcURL && editorOpen === false) {
        URL.revokeObjectURL(editorState.srcURL);
      }
    };
  }, [editorOpen, editorState]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <Dialog
      open={editorOpen}
      onClose={() => setEditorOpen(false)}
      fullWidth
      maxWidth="xl"
      sx={{
        "& .MuiDialog-paper": {
          m: 0,
          maxHeight: "95vh",
          backgroundColor: "#0f082d",
        },
      }}
    >
      <Stack
        maxHeight="95vh"
        width="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          sx={{
            height: "95vh",
            width: "100%",
            backgroundColor: "#0f082d",
          }}
        >
          {isMdOrBelow ? (
            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={handleDrawerClose}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                zIndex: (theme) => theme.zIndex.modal + 1,
                "& .MuiDrawer-paper": {
                  backgroundColor: "#211a40",
                  color: "#fff",
                  width: "75%",
                  minWidth: "200px",
                  maxWidth: "300px",
                },
              }}
            >
              <ClipBar
                isMdOrBelow={isMdOrBelow}
                handleDrawerClose={handleDrawerClose}
              />
            </Drawer>
          ) : (
            <Grid item xs={12} lg={3}>
              <Box
                sx={{
                  height: { xs: "auto", lg: "100vh" },
                  backgroundColor: "#211a40",
                  color: "#fff",
                  borderRight: { lg: "1px solid #422770" },
                  overflowY: "auto",
                  scrollbarWidth: "none",
                  width: { xs: "100%", lg: "20%" },
                  minWidth: "300px",
                  maxWidth: "400px",
                }}
              >
                <ClipBar />
              </Box>
            </Grid>
          )}
          <Grid item xs={12} lg={8} xl={9}>
            <Box
              sx={{
                width: "100%",
                height: { xs: "auto", lg: "95vh" },
                marginLeft: { lg: "30px", xl: "0" },
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Stack sx={{}}>
                <EditPanel vdoSrc={editorState.srcURL} />
              </Stack>

              {isMdOrBelow && (
                <Box
                  sx={{
                    p: 2,
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "100%",
                  }}
                >
                  <Button
                    startIcon={<VideoCameraBack/>}
                    onClick={handleDrawerOpen}
                    sx={{ marginRight: 2 }}
                  >
                    Clips
                  </Button>
                </Box>
              )}

              <Stack
                sx={{
                  marginTop: "8px",
                  height: { xs: "50vh", lg: "60%" },
                  overflowY: "auto",
                  scrollbarWidth: "none",
                }}
              >
                <TextTracker />
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </Dialog>
  );
};

export default VideoEditor;
