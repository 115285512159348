//transcript[0] contains the segments
//transcript[1] contains the words

import { CircularProgress, Stack } from "@mui/material";

const findIndex = (time, transcript,after=0) => {
  let bestIndex = 0;
  let bestDiff = 9999999999;

  for (let i = after; i < transcript.length; i++) {
    let diff = Math.abs(transcript[i].start - time);
    if (diff < bestDiff) {
      bestDiff = diff;
      bestIndex = i;
    }

    if (diff > bestDiff) {
      break;
    }
  }
  return bestIndex;
};

const findEndIndex = (time, transcript, after=0) => {
  let bestIndex = 0;
  let bestDiff = 999999;

  // console.log("Finding end index",time,after,transcript.length,transcript[0]);
  for (let i = after; i < transcript.length; i++) {
    
    let diff = Math.abs(transcript[i].end - time);
    if (diff < bestDiff) {
      bestDiff = diff;
      bestIndex = i;
    }

    if (diff > bestDiff) {
      break;
    }
  }
  return bestIndex;
};

export const findSegmentText = (start, end, transcript) => {
  let startIndex = findIndex(start, transcript);
  let endIndex = findEndIndex(end, transcript);
};

export const findSelection = (start, end, transcript) => {
  // console.log("Finding selection",transcript[1]);
  let s = findIndex(start, transcript[1]);
  let e = findEndIndex(end, transcript[1], s);
  return [s, e];
};

/*
  [
    {
      start: 0,
      end: 10,
      startIndex: 0,
      endIndex: 0,
      text: "hello"
    }
  ]
*/
export const getSentences = (transcript) => {

  let sentences = [];
  let lastEndIndex=0;

  console.log(transcript)

  for (let i = 0; i < transcript[0].length; i++) {
    const sentence = {
      start: transcript[0][i].start,
      end: transcript[0][i].end,
      text: transcript[0][i].text.trim(),
      startIndex: lastEndIndex,
    }

    // let cnt=0;
    // i++;
    // while(cnt<3 && i<transcript[0].length){
      
    //   let t = transcript[0][i];
    //   if(t.text.endsWith(".") || t.text.endsWith("?") || t.text.endsWith("!")){
    //     cnt++
    //   }

    //   sentence.end = t.end;
    //   sentence.text += t.text;
      
    //   i++;
    // }

    // sentence.text = sentence.text.trim();
    let word_cnt = sentence.text.match(/\S+/g);
    if(word_cnt){
      word_cnt = word_cnt.length;
    }
    else word_cnt = 1;
    
    sentence.endIndex = sentence.startIndex + word_cnt;
    lastEndIndex = sentence.endIndex+1;

    sentences.push(sentence);
  }

  return sentences;
}

// async function makeVideo(suggestions) {
//   const ext = video.file.name.split(".")[1];
//   const target = video.name + "_output." + ext;
//   setStatus(STATUS.SPLITING_VIDEO);

//   let commands = [];
//   let last_cmd = "";
//   for (let i = 0; i < suggestions.length; i++) {
//     const s = suggestions[i];
//     let dm = `[0:v]trim=start=${s.start}:end=${s.end},setpts=PTS-STARTPTS[v${
//       i + 1
//     }];`;
//     dm += `[0:a]atrim=start=${s.start}:end=${s.end},asetpts=PTS-STARTPTS[a${
//       i + 1
//     }];`;
//     commands.push(dm);
//     last_cmd += `[v${i + 1}][a${i + 1}]`;
//   }

//   commands = commands.join("");
//   last_cmd += `concat=n=${suggestions.length}:v=1:a=1[outv][outa]`;

//   commands += last_cmd;
//   // console.log(commands);

//   await ffmpeg.exec([
//     "-i",
//     video.file.name,
//     "-filter_complex",
//     commands,
//     "-map",
//     "[outv]",
//     "-map",
//     "[outa]",
//     target,
//   ]);

//   const data = await ffmpeg.readFile(target);
//   let blob = new Blob([data.buffer], { type: "video/" + ext });

//   setOutputVideo(blob);
//   setStatus(STATUS.DONE);
// }

const download = (blob, name) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = name;
  a.click();
  URL.revokeObjectURL(url);
};

export const makeVideo = async (start, end, ffmpeg, output_name, ext, video) => {
  let dm = `[0:v]trim=start=${start}:end=${end},setpts=PTS-STARTPTS[v1];`;
  dm += `[0:a]atrim=start=${start}:end=${end},asetpts=PTS-STARTPTS[a1];`;

  const commands = dm + `[v1][a1]concat=n=1:v=1:a=1[outv][outa]`;

  await ffmpeg.exec([
    "-i",
    video.file.name,
    "-filter_complex",
    commands,
    "-map",
    "[outv]",
    "-map",
    "[outa]",
    output_name,
  ]);

  const data = await ffmpeg.readFile(output_name);
  let blob = new Blob([data.buffer], { type: "video/" + ext });

  download(blob, output_name);
};

/*
[

    {
        start: 0,
        end: 10
        selection: [0,1] //word index
    }
]

*/
export const makeVideos = async (suggestions, ffmpeg, video, setMsg) => {
  const promises = [];

  setMsg(
    <Stack gap={2} display="flex" direction="row" marginTop={2}>
      <CircularProgress size={18} />{" "}
      <label>making {suggestions.length} videos</label>
    </Stack>
  );
  for (let i = 0; i < suggestions.length; i++) {
    const s = suggestions[i];
    const ext = video.file.name.split(".")[1];
    const target = video.name + "_output_" + i + "." + ext;

    promises.push(makeVideo(s.start, s.end, ffmpeg, target, ext, video));
  }

  await Promise.all(promises);
};


