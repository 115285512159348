// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBaD2e8RU_uhztRZFaTq1m6DdbrDnAHsSM",
  authDomain: "video-transcibe.firebaseapp.com",
  projectId: "video-transcibe",
  storageBucket: "video-transcibe.appspot.com",
  messagingSenderId: "865729535425",
  appId: "1:865729535425:web:74a013b0b6d17053322340",
  measurementId: "G-VS3YM6P0FN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);