import { useRecoilState } from "recoil";
import {
  currentActiveVideoAtom,
  ffmpegLogsAtom,
  openEditorAtom,
} from "../atoms/atoms";
import { useContext, useEffect, useState } from "react";
import { FfmpegContext } from "../context/FFMPEGContext";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { Box, Button, Grid, Stack } from "@mui/material";
import { useFilePicker } from "use-file-picker";
import VideoProcessor from "./VideoProcessor";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import VideoEditor from "./VideoEditor";
import "./video-react.css";
import VideoMaker from "./VideoMaker";
const IDgenerator = () => {
  return "video_" + Math.random().toString(36).substr(2, 9) + "_" + Date.now();
};

const Home = () => {
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [finishedTasks, setFinishedTasks] = useState({});
  const [currentActiveVideo, setCurrentActiveVideo] = useRecoilState(
    currentActiveVideoAtom
  );

  const { openFilePicker } = useFilePicker({
    multiple: true,
    accept: "video/*",
    readFilesContent: false,
    onFilesSelected: ({ plainFiles, filesContent }) => {
      // this callback is called when there were no validation errors
      console.log("onFilesSuccessfullySelected", plainFiles);
      for (let i = 0; i < plainFiles.length; i++) {
        const file = plainFiles[i];
        const id = IDgenerator();
        const onlyName = file.name.substring(0, file.name.lastIndexOf("."));
        setSelectedVideos((prev) => [...prev, { id, file, name: onlyName }]);
      }
    },
  });

  // if(Date.now() > 1712858400000){
  //   return <h1>Expired payment time</h1>
  // }

  useEffect(() => {
    console.log("finished tasks changed", finishedTasks);
  }, [finishedTasks]);

  const aTaskIsComplete = (id) => {
    setFinishedTasks((prev) => {
      let has_more = false;
      for (let i = 0; i < selectedVideos.length; i++) {
        if (!prev[selectedVideos[i].id] && selectedVideos[i].id !== id) {
          setCurrentActiveVideo(selectedVideos[i].id);
          has_more = true;
          break;
        }
      }
      if (!has_more) setCurrentActiveVideo(null);

      return { ...prev, [id]: true };
    });
  };

  const startOrPause = () => {
    if (currentActiveVideo) {
      // pause
      setCurrentActiveVideo(null);
    } else {
      // start
      for (let i = 0; i < selectedVideos.length; i++) {
        if (!finishedTasks[selectedVideos[i].id]) {
          setCurrentActiveVideo(selectedVideos[i].id);
          return;
        }
      }

      //all done
      setCurrentActiveVideo(null);
    }
  };

  const deleteThis = (id) => {
    setSelectedVideos((prev) => prev.filter((video) => video.id !== id));
  };

  return (
    < >
      <Box
        padding="10px 0"
        alignItems="center"
        justifyContent="center"
        display="flex"
        gap="20px"
        width="100%"
      >
        <Button
          onClick={openFilePicker}
          startIcon={<AddBoxIcon />}
          variant="contained"
        >
          Add video
        </Button>
        <Button
          onClick={startOrPause}
          disabled={selectedVideos.length === 0}
          startIcon={
            currentActiveVideo ? <PauseCircleIcon /> : <PlayCircleOutlineIcon />
          }
          variant="contained"
        >
          {currentActiveVideo ? "Stop" : "Start"}
        </Button>
      </Box>

      <Stack
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        flexDirection="column"
      >
        {selectedVideos.map((video) => (
          <Stack margin="10px 20px" maxWidth="800px" width="90%">
            <VideoProcessor
              video={video}
              setFinished={aTaskIsComplete}
              deleteThis={deleteThis}
            />
          </Stack>
        ))}
      </Stack>

      <VideoEditor />
      <VideoMaker />
    </>
  );
};

export default Home;
