// RefContext.js
import React, { createContext, useEffect, useRef, useState } from "react";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from "@ffmpeg/util";
import { useRecoilState } from "recoil";
import { ffmpegLogsAtom, videoProcessorProgressAtom } from "../atoms/atoms";
import { FFMEPG_BASE } from "../constants";
import Loader from "../components/Loader";

export const FfmpegContext = createContext();

// Create a Provider Component
export function FFMPEGProvider({ children }) {
  // const [logs, setLogs] = useRecoilState(ffmpegLogsAtom); // [message, message, message, ...]
  const [progressMsg, setProgressMsg] = useRecoilState(
    videoProcessorProgressAtom
  );
  const [loaded, setLoaded] = useState(false);
  const ffmpegRef = useRef(new FFmpeg());

  useEffect(() => {
    const load = async () => {
      try {
        const ffmpeg = ffmpegRef.current;
        console.log("Loading ffmpeg");

        ffmpeg.on("progress", ({ progress, time }) => {
          progress = progress * 100;
          progress = progress.toFixed(2);
          const msg = `${progress} % (transcoded time: ${time / 1000000} s)`;

          setProgressMsg({ msg, progress });
        });

        ffmpeg.on("log", (msg) => {
          console.log("FFMPEG LOG: ", msg);
        });

        await ffmpeg.load({
          coreURL: await toBlobURL(
            `${FFMEPG_BASE}/ffmpeg-core.js`,
            "text/javascript"
          ),
          wasmURL: await toBlobURL(
            `${FFMEPG_BASE}/ffmpeg-core.wasm`,
            "application/wasm"
          ),
        });
        setLoaded(true);
      } catch (err) {
        console.log(err);
      }
    };

    load();
  }, []);

  return (
    <FfmpegContext.Provider value={ffmpegRef}>
      {loaded ? (
        children
      ) : (
        <div style={{ height: "95vh" }}>
          <Loader msg="Loading editor resources" />
        </div>
      )}
    </FfmpegContext.Provider>
  );
}
