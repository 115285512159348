import React, { useEffect, useState } from "react";
import YourClipsCard from "./YourClipsCard";
import { Alert, Box, IconButton, Typography } from "@mui/material";
import AIGeneratedClips from "./GeneratedClipsCard";
import {
  editorStateAtom,
  globalSelectionsAtom,
  selectedClipIdsAtom,
  selectionRangeAtom,
  selectionsChangesAtom,
} from "../atoms/atoms";
import { useRecoilState, useSetRecoilState } from "recoil";
import { findSelection } from "../pages/util";
import CloseIcon from "@mui/icons-material/Close";

const ClipBar = ({ isMdOrBelow, handleDrawerClose }) => {
  const [editorState, setEditorState] = useRecoilState(editorStateAtom);
  const [globalSelection, setGlobalSelection] =
    useRecoilState(globalSelectionsAtom);
  const setSelectionRange = useSetRecoilState(selectionRangeAtom);

  const [maxTime, setMaxTime] = useState(100);

  useEffect(() => {
    let words = editorState.transcript[1];
    if (!words) return;

    setMaxTime(words[words.length - 1].end);
  }, [editorState]);

  const changeValue = (v, index) => {
    let selection = findSelection(v[0], v[1], editorState.transcript);
    setSelectionRange({
      start: selection[0],
      end: selection[1],

      startTime: v[0],
      endTime: v[1],
    });

    // setSelectionUpdated((p) => !p);
  };

  return (
    <Box style={{ margin: "0" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          style={{
            textAlign: "left",
            color: "#fff",
            marginLeft: "12px",
            padding: "12px",
            marginTop: "0",
          }}
        >
          Clips
        </Typography>

        {isMdOrBelow && (
          <IconButton
            onClick={handleDrawerClose}
            sx={{ marginRight: "12px", height: "40px", width: "40px" }}
          >
            <CloseIcon color="primary" />
          </IconButton>
        )}
      </Box>

      <Alert
        icon={false}
        severity="success"
        sx={{
          color: "#fff",
          margin: "12px",
          padding: "0 6px",
          backgroundColor: "#1a485a",
          borderLeft: "10px solid #00ffc2",
        }}
      >
        Your clips ({globalSelection.length})
      </Alert>

      {globalSelection.length > 0 ? (
        globalSelection.map((clip, index) => (
          <YourClipsCard
            key={index}
            id={index}
            title={"Clip " + (index + 1)}
            startTime={clip.start}
            endTime={clip.end}
            srcURL={editorState.srcURL}
            video={editorState.video}
            maxTime={maxTime}
            changeValue={(v) => {
              changeValue(v, index);
            }}
            selection={clip.selection}
          />
        ))
      ) : (
        <Typography
          variant="body1"
          style={{
            color: "#fff",
            textAlign: "center",
            margin: "12px",
          }}
        >
          No clips available
        </Typography>
      )}
    </Box>
  );
};

export default ClipBar;
