import { useRecoilState } from "recoil";
import { userAtom } from "../atoms/atoms";
import { Stack, Typography } from "@mui/material";
import GoogleButton from "react-google-button";
import { signInWithPopup, GoogleAuthProvider, getAuth } from "firebase/auth";
const Login = () => {
  const login = async () => {
    try {
      await signInWithPopup(getAuth(), new GoogleAuthProvider());
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Stack
      spacing={4}
      direction="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100vh"
    >
      <Typography variant="h3">Login to continue</Typography>
      <GoogleButton onClick={login} />
    </Stack>
  );
};

export default Login;
