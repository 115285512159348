import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  TextField as TF,
  Typography,
  styled,
} from "@mui/material";
import { userAtom, userDataAtom } from "../atoms/atoms";
import { useRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { getAuth, signOut } from "firebase/auth";
import { toast } from "react-toastify";
import LicenseActivator from "./LicenseActivator";
import { Logout } from "@mui/icons-material";

export const TextField = styled(TF)`
  & label {
    color: white;
  }

  & label.Mui-disabled {
    color: grey;
  }

  & .MuiOutlinedInput-root {
    fieldset {
      border: 1px solid white;
    }

    &.Mui-disabled {
      & fieldset {
        border: 1px solid grey;
        color: grey !important;
      }
    }
  }
`;
const Settings = ({ open, setOpen }) => {
  const [userData, setUserData] = useRecoilState(userDataAtom);
  const [showSignOut, setShowSignOut] = useState(false);

  useEffect(() => {
    if (!userData.changed) return;

    const saveUserData = async () => {
      await setDoc(
        doc(getFirestore(), "users", getAuth().currentUser.uid),
        userData,
        { merge: true }
      );
    };

    const timeOut = setTimeout(saveUserData, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [userData]);

  useEffect(() => {
    if (!userData.fetched) return;

    if (
      userData.openAI_API_KEY === "" ||
      (userData.use_claude && userData.claude_API_KEY === "") ||
      userData.promptTemplate === "" ||
      !userData.license ||
      userData.expiry < Date.now()
    ) {
      setOpen(true);
      setShowSignOut(true);
    }
  }, [userData]);

  const closeMe = () => {
    if (
      userData.openAI_API_KEY === "" ||
      (userData.use_claude && userData.claude_API_KEY === "") ||
      userData.promptTemplate === ""
    ) {
      toast.warn("Please fill all the fields");
      return;
    }

    if (!userData.license) {
      toast.warn("Please activate your license");
      return;
    }

    if (userData.license && userData.expiry < Date.now()) {
      toast.warn("Your license has expired");
      return;
    }
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={closeMe} fullWidth>
      <DialogTitle>Settings</DialogTitle>
      <DialogContent>
        <Box
          width="100%"
          gap="20px"
          display="flex"
          flexDirection="column"
          padding="10px 0px"
        >
          <LicenseActivator />

          {userData.openAI_API_KEY === "" ||
          (userData.use_claude && userData.claude_API_KEY === "") ||
          userData.promptTemplate === "" ? (
            <Alert severity="warning">
              Please fill all the fields carefully before you proceed
            </Alert>
          ) : null}
          <TextField
            value={userData.openAI_API_KEY}
            onChange={(e) =>
              setUserData((prev) => ({
                ...prev,
                openAI_API_KEY: e.target.value,
                changed: true,
              }))
            }
            fullWidth
            label="OpenAI API key"
            placeholder="OpenAI API key"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={userData.use_claude}
                onChange={(e) =>
                  setUserData((p) => {
                    return { ...p, use_claude: e.target.checked };
                  })
                }
              />
            }
            label="Use 'Claude' for  transcript analysis"
          />

          {userData.use_claude && (
            <TextField
              value={userData.claude_API_KEY}
              onChange={(e) =>
                setUserData((prev) => ({
                  ...prev,
                  claude_API_KEY: e.target.value,
                  changed: true,
                }))
              }
              fullWidth
              label="Claude API key"
              placeholder="Claude API key"
            />
          )}
          <TextField
            value={userData.promptTemplate}
            onChange={(e) =>
              setUserData((prev) => ({
                ...prev,
                promptTemplate: e.target.value,
                changed: true,
              }))
            }
            fullWidth
            multiline
            rows={8}
            label="Prompt Template"
            placeholder="Template for the prompt"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {showSignOut && (
          <Button
            onClick={() => {
              signOut(getAuth());
            }}
            startIcon={<Logout />}
          >
            sign out
          </Button>
        )}
        <Button variant="outlined" onClick={closeMe}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Settings;
