import styled from "@emotion/styled";
import { Stack } from "@mui/material";

const LoaderText = styled.div`
  font-size: 1.5em;
  font-weight: bold;
  color: #ff0000;
  text-align: center;
  margin-top: 20px;
  max-width: 300px;
`;

const Loader = ({ msg, scale }) => {
  return (
    <Stack
      spacing={2}
      direction="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      style={{
        transform: `scale(${scale || 1})`,
      }}
    >
      <span className="loader"></span> <br></br>
      <LoaderText>{msg}</LoaderText>
    </Stack>
  );
};

export default Loader;
